.Main {
  text-align: center;
}

.Main-logo {
  height: 25vh;
  border-radius: 12px;
}


.Main-header {
  background-color: #456;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}

.padding {
  padding-top: 12px;
}

